<template>
        <v-bottom-sheet  :persistent="true" v-model="modelValue" max-width="425px">
            <v-sheet>
            <div class="bottomSheetContainer">
                <div class="bottomSheetHeader">
                    <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">{{$t("Customer.OpdAppointments.view_doctor")}}</div>
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/closeCircular.svg" style="cursor: pointer;" @click="closeDialog"/>
                </div>
                <div style="height: 90vh; overflow: scroll;">
                    <img :src="doctorInfo.doctorProfileImage" alt="" class="profileImage">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 24px; width: 100%; " class="doctorBodyContainer">
                        <div class="doctorName" style="font-size: 24px; font-weight: 600;">{{ doctorInfo.doctorNameEN }}

                            <img  :src="getIcon(doctorInfo.gender)" alt="">

                        </div>
                            <!-- <div style="display: flex; align-items: center; justify-content: center; gap: 4px;">
                                <img  :src="getIcon(doctorInfo.gender)" alt="">
                                <div v-if="doctorInfo.gender.toUpperCase() == 'FEMALE'" style="font-size: 16px; color: #eb57c2; font-weight: 500;">{{ doctorInfo.gender }}</div>
                                <div v-else style="font-size: 16px; color: #eb57c2; font-weight: 500; color: rgb(77, 159, 246);">{{ doctorInfo.gender }}</div>

                            </div> -->
                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                            <div style="display: flex; align-items: center; justify-content: center; gap: 10px; background-color: #e4f5ff; padding: 6px 12px 6px 12px;  border-radius: 4px" >
                                <img src="" alt="">
                                <div style="color: #1467bf; font-weight: 500; font-size: 14px;" v-if="specialtyList">{{ getSpecialtyName(doctorInfo.doctorSpecialty) }}</div>
                            </div>
                            <!-- <div style="display: flex; gap: 8px;">
                                <div v-for="i in 5" :key="i" >
                                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/whiteHeart.svg" style="width: 25px; height: 25px;">
                                </div>
                            </div> -->
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px; border-top: 1px solid #f2f2f2; width: 100%; padding-top: 16px;" >
                            <div style="font-size: 16px; font-weight: 600; padding-bottom: 16px;">Qualifications</div>
                            <div style="display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; text-align: left; gap: 8px;">
                                <div  v-for="(item, index) in doctorInfo.doctorAcademicTitle" :key="index" v-if="academicsList[item]"><div style="max-width: 350px;  background-color: #f2f2F2; border: 1px solid #e0e0e0; color:#4f4f4f;  border-radius: 32px; padding: 10px; margin-bottom: 2px; font-weight: 400; font-size: 12px;"> {{ academicsList[item].name }}</div></div>
                            </div>

                        </div>
                        <div style="display: flex; flex-direction: column; gap: 8px; align-items: flex-start; width: 100%;">
                            <div style="font-size: 16px; font-weight: 600;">{{$t("Customer.OpdAppointments.over_all_status")}}</div>
                            <!-- <div style="display: flex; align-items: center; gap: 8px; justify-content: space-evenly; width: 100%;">
                                <div class="experienceBox" style="flex-grow: 1">
                                    <div style="font-size: 24px; font-weight: 700;">{{ doctorInfo.yoe }}+</div>
                                    <div style="font-size: 12px; color: #828282; font-weight: 500;">{{$t("Customer.OpdAppointments.year_of_exp")}}</div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div style="display: flex; flex-direction: column; gap: 8px; align-items: flex-start; width: 100%;"> -->
                            <!-- <div style="font-size: 16px; font-weight: 600;">Overall Stats</div> -->
                            <!-- <div style="display: flex; align-items: center; gap: 8px; justify-content: space-evenly; width: 100%;"> -->
                                <!-- <div class="experienceBox" style="flex-grow: 1">
                                    <div style="font-size: 24px; font-weight: 700;">{{ doctorInfo.yoe }}+</div>
                                    <div style="font-size: 12px; color: #828282; font-weight: 500;">Years of Exp</div>
                                </div> -->
                                <!-- <div class="experienceBox" style="flex-grow: 1">
                                    <div style="font-size: 24px; font-weight: 700;">{{ doctorInfo.noOfPatients }}</div>
                                    <div style="font-size: 12px; color: #828282; font-weight: 500;">No Of Patients</div>
                                </div> -->
                                <!-- <div class="experienceBox">
                                    <div style="font-size: 24px; font-weight: 700;">{{ doctorInfo.reviews }}</div>
                                    <div style="font-size: 12px; color: #828282; font-weight: 500;">Reviews</div>
                                </div> -->
                            <!-- </div>
                        </div> -->
                        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                            <div style="font-size: 16px; font-weight: 600;">{{$t("Customer.OpdAppointments.About")}}</div>
                            <div style="text-align: justify; font-size: 14px; font-weight: 400; color: #7c8287;">{{ doctorInfo.about }}</div>
                        </div>
                        <!-- <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                            <div style="font-size: 16px; font-weight: 600;">Reviews</div>
                            <div style="text-align: justify; font-size: 14px; font-weight: 400; color: #7c8287;">Let's do it in sprint 3</div>
                        </div> -->
                    </div>
                </div>
            </div>
            </v-sheet>
        </v-bottom-sheet>
</template>

<script>
export default {
    name: 'ViewDoctorComponent',
    props: ['doctorInfo', 'modelValue'],
    data: function() {
        return {
            x: true,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            bookingID: null,
            // bookingInfo: {},
        };
    },
    computed: {
        showHospitalLoader () {
            return this.$store.state.particularHospitalState.fetchingData;        
        },
        getDocotorList() {
            return this.$store.state.particularHospitalState.shownDoctorList || [];        
        },
        academicsList () {
            return this.$store.state.OPDConfigCommonState.academics;        
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
    },
    async mounted() {},

    methods: {
        getIcon(gender) {
            if (gender.toUpperCase() == 'FEMALE') {
                return 'https://d3vsnl8idgwrlw.cloudfront.net/genderF.svg'
            } else {
                return 'https://d3vsnl8idgwrlw.cloudfront.net/genderM.svg'
            }
        },
        getSpecialtyName(item) {
            let findItem = this.specialtyList.find((x) => (x.id == item));
            console.log(findItem, item, this.specialtyList);
            if (findItem) {
                return findItem.name;
            } else {
                return ''
            }
        },
        closeDialog() {
            this.$emit('toggleViewDoctorDialog', false);
        },
        disableBackNavigation() {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        this.$router.go(-1); // Prevent back navigation
      };
    },
        goToPage(value) {
            this.$router.push({
                name: value,
            });
        },
    },
};

</script>
<style  scoped>
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin: auto;
    width: 425px;
    height: 100vh;
    background-color: white;
    /* max-height: 100vh; */
    /* overflow: hidden; */
}
.bodyContainer {
    width: 100%;
    /* height: 100%; */
    padding: 0 16px;
    background-color: white;
    /* background: red; */
}
.bottomBar {
    position: absolute;
    bottom: 0;
    /* background: ; */
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 100%;
    max-width: 425px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-evenly;
}
.bottomSheetContainer {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 90vh;
    background: #fff;
    border: 1px solid #ebebe8;
    border-radius: 16px;
    max-width: 425px;
    /* max-height: 70vh; */
    /* overflow-y: scroll; */
    /* z-index: 1000; */
}
.doctorBodyContainer {
    padding: 32px 16px 32px 16px;
    border-radius: 32px 32px 0 0;
    /* border: 1px solid red; */
    z-index: 1000;
    background: #fff;
    position: relative;
    top: -32px;
}
.experienceBox {
    padding: 8px 16px 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.bottomSheetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8ebeb;
    /* position: sticky; */
    /* top: 0; */
}
.profileImage {
    height: 300px;
    /* min-height: 300px; */
    max-width: 425px;
    object-fit: cover;
    width: 100%;
    /* position: relative; */
    /* bottom: -30px; */
    z-index: -1;
}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>